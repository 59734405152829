.recent-orders{
    &-col {
        vertical-align: top;
        
        &__title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &--up {
                font-size: 16px;

            }
        }

        .orderId {
            cursor: pointer;
        }
    }
}