.trending-menu-wrapper {
  .menu-item {
    margin-bottom: 45px;
  }
  .menu-item__avatar {
    position: relative;
  }
  .menu-item__avatar-circle {
    background-color: #67BE23;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 100%;
    left: 50%;
    border: 4px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 800;
  }
  .menu-item__text {
    display: flex;
    flex-direction: column;
  }
  .menu-item__text span {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    .menu-item__avatar-circle {
      width: 28px;
      height: 28px;
      font-size: 10px;
    }
    .menu-item__text span {
      font-size: 16px;
    }
  }
}